.switch {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 40px;
}
.switch input {
  opacity: 0;
  height: 0;
  width: 0;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #b4b4b4;
  border-radius: 40px;
  transition: 0.3s;
}
.slider:before {
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.3s;
}
input:checked + .slider:before {
  transform: translateX(20px);
  background-color: #fff;
}
input:checked + .slider {
  background-color: black;
}

.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 830px;
  padding-top: 56.25%;
  border: 5px solid black;
}

.youtube-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}